var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var bda,eG,hG,tG;$CLJS.WF=function(a){return $CLJS.Ck.g($CLJS.Ze.h(a),$CLJS.eE)};$CLJS.XF=function(a,b){a=$CLJS.cv($CLJS.tC(a,/''/,"'"),/\{\d+\}/);return $CLJS.P.j($CLJS.Au.t,$CLJS.Xk(a),$CLJS.Xk(b))};$CLJS.YF=new $CLJS.K("lib","metadata","lib/metadata",1798917220);$CLJS.ZF=new $CLJS.K("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.$F=new $CLJS.K(null,"num-bins","num-bins",847672055);$CLJS.aG=new $CLJS.K(null,"mbql","mbql",69346710);
$CLJS.bG=new $CLJS.K(null,"display-name","display-name",694513143);bda=new $CLJS.K("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.cG=new $CLJS.K("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.dG=new $CLJS.K("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);
eG=new $CLJS.K("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.fG=new $CLJS.K("lib","external-op","lib/external-op",-1470923877);$CLJS.gG=new $CLJS.K("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);hG=new $CLJS.K("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.iG=new $CLJS.K(null,"database-type","database-type",-426840562);
$CLJS.jG=new $CLJS.K(null,"operator","operator",-1860875338);$CLJS.kG=new $CLJS.K(null,"fk-target-field-id","fk-target-field-id",2019750287);$CLJS.lG=new $CLJS.K("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.mG=new $CLJS.K("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.nG=new $CLJS.K("lib","uuid","lib/uuid",-2145250720);
$CLJS.oG=new $CLJS.K(null,"x","x",2099068185);$CLJS.pG=new $CLJS.K("metabase.lib.schema.id","field","metabase.lib.schema.id/field",-985236431);$CLJS.qG=new $CLJS.K("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.rG=new $CLJS.K(null,"bin-width","bin-width",1377922579);$CLJS.sG=new $CLJS.K(null,"strategy","strategy",-1471631918);tG=new $CLJS.K("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.Y($CLJS.gG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.h(null,1,[$CLJS.Ln,1],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"non-blank string"],null),$CLJS.Ke($CLJS.uC)],null)],null));$CLJS.Y($CLJS.lG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tt,new $CLJS.h(null,1,[$CLJS.Ln,0],null)],null));$CLJS.Y($CLJS.ZF,$CLJS.Bl);$CLJS.Y(tG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.h(null,2,[$CLJS.Ln,36,$CLJS.Zj,36],null)],null));
$CLJS.Y(hG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,2,[$CLJS.vu,"valid semantic type",$CLJS.xu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return["Not a valid semantic type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.fE(a,$CLJS.jj)}],null));
$CLJS.Y(eG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,2,[$CLJS.vu,"valid relation type",$CLJS.xu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return["Not a valid relation type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.fE(a,$CLJS.fi)}],null));$CLJS.Y($CLJS.dG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eG],null)],null));
$CLJS.Y($CLJS.qG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,2,[$CLJS.vu,"valid base type",$CLJS.xu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return["Not a valid base type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.fE(a,$CLJS.rj)}],null));
$CLJS.Y($CLJS.mG,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nG,tG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gD,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.qG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.qG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Mj,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,
$CLJS.dG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iG,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bG,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.gG],null)],null)],null));
$CLJS.Y(bda,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.fG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.Qj,$CLJS.Ni],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.yt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Xi,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.mG],null)],null));