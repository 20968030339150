var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var ZJ,$J,uea,vea,wea,dK,eK,fK,hK,xea,jK;ZJ=function(a){switch(arguments.length){case 2:return $CLJS.fH(arguments[0],arguments[1]);case 3:return $CLJS.eH(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$J=function(a){return["(?:",$CLJS.p.h($CLJS.P.g($CLJS.p,a)),")?"].join("")};uea=new $CLJS.K("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
vea=new $CLJS.K("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.aK=new $CLJS.K("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);wea=new $CLJS.K("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.bK=new $CLJS.K("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
$CLJS.cK=new $CLJS.K("metabase.lib.schema.literal","string.zone-offset","metabase.lib.schema.literal/string.zone-offset",-437074263);dK=new $CLJS.K("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);eK=new $CLJS.K("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);fK=new $CLJS.K("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);
$CLJS.gK=new $CLJS.K("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);hK=new $CLJS.K("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);xea=new $CLJS.K("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.iK=new $CLJS.K("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);
jK=new $CLJS.K("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);$CLJS.kK=new $CLJS.K("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.BG.m(null,$CLJS.jD,function(){return $CLJS.rj});$CLJS.Y(jK,$CLJS.xt);$CLJS.BG.m(null,$CLJS.mD,function(){return $CLJS.Cj});$CLJS.Y(jK,$CLJS.xt);$CLJS.Y(xea,$CLJS.tt);$CLJS.BG.m(null,$CLJS.ZC,function(){return $CLJS.nj});$CLJS.Y(vea,$CLJS.st);$CLJS.BG.m(null,$CLJS.XC,function(){return $CLJS.BF});$CLJS.Y(wea,$CLJS.Qj);
var yea=[":\\d{2}",$J($CLJS.G(["\\.\\d{1,6}"]))].join(""),lK=["\\d{2}:\\d{2}",$J($CLJS.G([yea]))].join(""),mK=["\\d{4}-\\d{2}-\\d{2}T",lK].join(""),nK=["(?:Z|(?:[+-]",lK,"))"].join(""),zea=$CLJS.hh(["(?:Z|(?:[+-]",lK,"))"].join("")),Aea=$CLJS.hh("^\\d{4}-\\d{2}-\\d{2}$"),Bea=$CLJS.hh(["^",lK,"$"].join("")),Cea=$CLJS.hh(["^",lK,nK,"$"].join("")),Dea=$CLJS.hh(["^",mK,"$"].join("")),Eea=$CLJS.hh(["^",mK,nK,"$"].join(""));
$CLJS.Y(eK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.vu,"date string literal"],null),Aea],null));$CLJS.Y($CLJS.cK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.vu,"timezone offset string literal"],null),zea],null));
$CLJS.Y(dK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.vu,"local time string literal"],null),Bea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.vu,"offset time string literal"],null),Cea],null)],null));
$CLJS.Y(fK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.vu,"local date time string literal"],null),Dea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.vu,"offset date time string literal"],null),Eea],null)],null));
$CLJS.BG.m(null,$CLJS.YC,function(a){return $CLJS.n($CLJS.fH?$CLJS.fH(fK,a):ZJ.call(null,fK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.Uj,null,$CLJS.eF,null],null),null):$CLJS.n($CLJS.fH?$CLJS.fH(eK,a):ZJ.call(null,eK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.GE,null,$CLJS.Uj,null],null),null):$CLJS.n($CLJS.fH?$CLJS.fH(dK,a):ZJ.call(null,dK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.EF,null,$CLJS.Uj,null],null),null):$CLJS.Uj});$CLJS.Y($CLJS.gK,eK);$CLJS.Y($CLJS.bK,dK);
$CLJS.Y($CLJS.kK,fK);$CLJS.Y(uea,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gt,$CLJS.gK,$CLJS.bK,$CLJS.kK],null));$CLJS.oK=$CLJS.hh("^\\d{4}-\\d{2}$");$CLJS.Y($CLJS.iK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.vu,"year-month string literal"],null),$CLJS.oK],null));$CLJS.pK=$CLJS.hh("^\\d{4}$");$CLJS.Y($CLJS.aK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.vu,"year string literal"],null),$CLJS.pK],null));
$CLJS.Y(hK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,$CLJS.qG],null)],null)],null));$CLJS.yI.g($CLJS.uj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.jt,new $CLJS.h(null,1,[$CLJS.vu,"Value :value clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.uj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hK],null),$CLJS.pl],null));