var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var a6,e6,f6,q6,s6,t6,$na,u6,D6,O6,P6,R6;a6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.common","metabase.lib.schema.drill-thru/drill-thru.common",1573044895);$CLJS.b6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.pk","metabase.lib.schema.drill-thru/drill-thru.pk",339574481);$CLJS.c6=new $CLJS.K("drill-thru","quick-filter","drill-thru/quick-filter",-1465756422);$CLJS.d6=new $CLJS.K(null,"row","row",-570139521);
e6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.fk-details.fk-column","metabase.lib.schema.drill-thru/drill-thru.fk-details.fk-column",-380335831);f6=new $CLJS.K("metabase.lib.schema.drill-thru","context.row.value","metabase.lib.schema.drill-thru/context.row.value",1438731480);$CLJS.g6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.distribution","metabase.lib.schema.drill-thru/drill-thru.distribution",937770403);$CLJS.h6=new $CLJS.K(null,"pivots","pivots",90109371);
$CLJS.i6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.pivot","metabase.lib.schema.drill-thru/drill-thru.pivot",-1408664484);$CLJS.j6=new $CLJS.K("metabase.lib.schema.drill-thru","context","metabase.lib.schema.drill-thru/context",122725764);$CLJS.k6=new $CLJS.K(null,"location","location",1815599388);$CLJS.l6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.column-filter","metabase.lib.schema.drill-thru/drill-thru.column-filter",603928893);
$CLJS.m6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.summarize-column-by-time","metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time",1590982743);$CLJS.n6=new $CLJS.K(null,"initial-op","initial-op",918033121);$CLJS.o6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.summarize-column","metabase.lib.schema.drill-thru/drill-thru.summarize-column",1770935578);$CLJS.p6=new $CLJS.K("drill-thru","column-filter","drill-thru/column-filter",1535293733);
q6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.automatic-insights","metabase.lib.schema.drill-thru/drill-thru.automatic-insights",671448614);$CLJS.r6=new $CLJS.K("drill-thru","fk-filter","drill-thru/fk-filter",1598101197);s6=new $CLJS.K("drill-thru","automatic-insights","drill-thru/automatic-insights",10252211);t6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries.next-unit","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries.next-unit",-2018118498);
$na=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru","metabase.lib.schema.drill-thru/drill-thru",-124537581);u6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.object-details","metabase.lib.schema.drill-thru/drill-thru.object-details",-725614444);$CLJS.v6=new $CLJS.K("drill-thru","sort","drill-thru/sort",511059541);$CLJS.w6=new $CLJS.K("drill-thru","summarize-column","drill-thru/summarize-column",-156285576);$CLJS.x6=new $CLJS.K(null,"sort-directions","sort-directions",300459345);
$CLJS.y6=new $CLJS.K(null,"aggregations","aggregations",-1081114338);$CLJS.z6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.fk-details","metabase.lib.schema.drill-thru/drill-thru.fk-details",233147406);$CLJS.A6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.quick-filter","metabase.lib.schema.drill-thru/drill-thru.quick-filter",-1703184103);$CLJS.B6=new $CLJS.K(null,"row-count","row-count",1060167988);$CLJS.C6=new $CLJS.K("drill-thru","distribution","drill-thru/distribution",1480876450);
D6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.quick-filter.operator","metabase.lib.schema.drill-thru/drill-thru.quick-filter.operator",-1269703311);$CLJS.E6=new $CLJS.K("drill-thru","underlying-records","drill-thru/underlying-records",-1420917644);$CLJS.F6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.fk-filter","metabase.lib.schema.drill-thru/drill-thru.fk-filter",-1219793148);
$CLJS.G6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.zoom","metabase.lib.schema.drill-thru/drill-thru.zoom",-262809778);$CLJS.H6=new $CLJS.K(null,"next-unit","next-unit",166270362);$CLJS.I6=new $CLJS.K("drill-thru","summarize-column-by-time","drill-thru/summarize-column-by-time",-765720821);$CLJS.J6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries",-890057316);
$CLJS.K6=new $CLJS.K("drill-thru","pivot","drill-thru/pivot",-1038336574);$CLJS.L6=new $CLJS.K("drill-thru","zoom-in.timeseries","drill-thru/zoom-in.timeseries",-168450975);$CLJS.M6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.underlying-records","metabase.lib.schema.drill-thru/drill-thru.underlying-records",1571614324);$CLJS.N6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.sort","metabase.lib.schema.drill-thru/drill-thru.sort",-920673199);
O6=new $CLJS.K("metabase.lib.schema.drill-thru","context.row","metabase.lib.schema.drill-thru/context.row",-1467255282);P6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.summarize-column.aggregation-type","metabase.lib.schema.drill-thru/drill-thru.summarize-column.aggregation-type",1395206778);$CLJS.Q6=new $CLJS.K("drill-thru","zoom","drill-thru/zoom",-1212878631);R6=new $CLJS.K("metabase.lib.schema.drill-thru","pivot-types","metabase.lib.schema.drill-thru/pivot-types",1174694312);$CLJS.Y(R6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.rI,$CLJS.k6,$CLJS.wA],null));$CLJS.Y(a6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,$CLJS.ee],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.eN],null)],null)],null));
$CLJS.Y(u6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YM,$CLJS.yt],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XM,$CLJS.xt],null)],null)],null));
$CLJS.Y($CLJS.b6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,u6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.bN],null)],null)],null)],null));$CLJS.Y(e6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kG,$CLJS.pG],null)],null)],null));
$CLJS.Y($CLJS.z6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,u6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.cN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,e6],null)],null)],null)],null));
$CLJS.Y($CLJS.G6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,u6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.Q6],null)],null)],null)],null));$CLJS.Y(D6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.BH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)],null));
$CLJS.Y($CLJS.A6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.c6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$M,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,D6],null)],null)],null)],null));
$CLJS.Y($CLJS.F6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.r6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.BH,$CLJS.MG],null)],null)],null));
$CLJS.Y($CLJS.g6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.C6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null)],null)],null)],null));
$CLJS.Y($CLJS.i6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.K6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.h6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,R6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null)],null)],null)],null)],null)],null));
$CLJS.Y($CLJS.N6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.v6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.x6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.ZM],null)],null)],null)],null));$CLJS.Y(P6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.SH,$CLJS.CH,$CLJS.kI],null));
$CLJS.Y($CLJS.o6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.w6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.y6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,P6],null)],null)],null)],null)],null));
$CLJS.Y($CLJS.m6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.I6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,$CLJS.VF],null)],null)],null));
$CLJS.Y($CLJS.l6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.p6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.n6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.vL],null)],null)],null)],null));
$CLJS.Y($CLJS.M6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.E6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B6,$CLJS.nl],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.k0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.Va],null)],null)],null)],null));
$CLJS.Y(q6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,s6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.eN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null)],null)],null)],null));
$CLJS.Y(t6,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ct,$CLJS.hi,$CLJS.oj,$CLJS.fj,$CLJS.pv,$CLJS.ov,$CLJS.nv],null));
$CLJS.Y($CLJS.J6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,a6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.L6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,$CLJS.Ta],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.H6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,t6],null)],null)],null)],null));
$CLJS.Y($na,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nt,a6,new $CLJS.Q(null,16,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,2,[$CLJS.Gi,$CLJS.dj,$CLJS.xu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return["Invalid drill thru (unknown :type): ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bN,$CLJS.b6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cN,$CLJS.z6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Q6,$CLJS.G6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.c6,$CLJS.A6],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.r6,$CLJS.F6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.C6,$CLJS.g6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.K6,$CLJS.i6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.v6,$CLJS.N6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.w6,$CLJS.o6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.I6,$CLJS.m6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.p6,$CLJS.l6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.E6,$CLJS.M6],null),new $CLJS.Q(null,2,5,$CLJS.R,[s6,q6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.L6,
$CLJS.J6],null)],null)],null));$CLJS.Y(f6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IH,$CLJS.Va],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,$CLJS.yt],null)],null));$CLJS.Y(O6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,f6],null)],null));
$CLJS.Y($CLJS.j6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.yt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.d6,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,O6],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aN,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,O6],null)],null)],null)],null));