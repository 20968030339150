var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var sK,uK,wK,IK,MK,UK;$CLJS.rK=new $CLJS.K(null,"get-month","get-month",-369374731);sK=new $CLJS.K("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.tK=new $CLJS.K(null,"convert-timezone","convert-timezone",-124803001);uK=new $CLJS.K("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.vK=new $CLJS.K(null,"iso","iso",-1366207543);wK=new $CLJS.K("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.xK=new $CLJS.K(null,"get-second","get-second",-2065946318);$CLJS.yK=new $CLJS.K(null,"get-year","get-year",-936011274);$CLJS.zK=new $CLJS.K(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.AK=new $CLJS.K("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.BK=new $CLJS.K(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.CK=new $CLJS.K(null,"datetime-diff","datetime-diff",1133112155);$CLJS.DK=new $CLJS.K(null,"interval","interval",1708495417);$CLJS.EK=new $CLJS.K(null,"us","us",746429226);$CLJS.FK=new $CLJS.K(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.GK=new $CLJS.K(null,"datetime","datetime",494675702);$CLJS.HK=new $CLJS.K(null,"now","now",-1650525531);IK=new $CLJS.K("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.JK=new $CLJS.K(null,"instance","instance",-2121349050);$CLJS.KK=new $CLJS.K(null,"get-day","get-day",127568857);$CLJS.LK=new $CLJS.K(null,"get-week","get-week",-888059349);
MK=new $CLJS.K("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.NK=new $CLJS.K(null,"datetime-add","datetime-add",209603411);$CLJS.OK=new $CLJS.K(null,"get-minute","get-minute",1468086343);$CLJS.PK=new $CLJS.K(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.QK=new $CLJS.K("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.RK=new $CLJS.K(null,"temporal-extract","temporal-extract",1781686405);$CLJS.SK=new $CLJS.K(null,"get-hour","get-hour",622714059);$CLJS.TK=new $CLJS.K(null,"datetime-subtract","datetime-subtract",275307697);UK=new $CLJS.K("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.VK=new $CLJS.K(null,"get-quarter","get-quarter",1327778593);$CLJS.WK=new $CLJS.K(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.XK=new $CLJS.K(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.YK=new $CLJS.K(null,"target","target",253001721);$CLJS.nH($CLJS.DK,$CLJS.G([$CLJS.lu,$CLJS.RE,$CLJS.tt,$CLJS.UF]));$CLJS.BG.m(null,$CLJS.AK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.CG(a);return $CLJS.ud(a)?(a=$CLJS.zv.g(a,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.GE,null,$CLJS.eF,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.gH($CLJS.AK,$CLJS.TG);
for(var ZK=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NK,$CLJS.TK],null)),$K=null,aL=0,bL=0;;)if(bL<aL){var cL=$K.X(null,bL);$CLJS.nH(cL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null),$CLJS.tt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UF],null)]));$CLJS.gH(cL,$CLJS.AK);bL+=1}else{var dL=$CLJS.z(ZK);if(dL){var eL=dL;if($CLJS.zd(eL)){var fL=$CLJS.kc(eL),Gea=$CLJS.lc(eL),Hea=fL,Iea=$CLJS.E(fL);ZK=Gea;$K=Hea;aL=Iea}else{var gL=$CLJS.B(eL);$CLJS.nH(gL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.KG],null),$CLJS.tt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UF],null)]));$CLJS.gH(gL,$CLJS.AK);ZK=$CLJS.C(eL);$K=null;aL=0}bL=0}else break}
for(var hL=$CLJS.z(new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.yK,$CLJS.rK,$CLJS.KK,$CLJS.SK,$CLJS.OK,$CLJS.xK,$CLJS.VK],null)),iL=null,jL=0,kL=0;;)if(kL<jL){var Jea=iL.X(null,kL);$CLJS.nH(Jea,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));kL+=1}else{var lL=$CLJS.z(hL);if(lL){var mL=lL;if($CLJS.zd(mL)){var nL=$CLJS.kc(mL),Kea=$CLJS.lc(mL),Lea=nL,Mea=$CLJS.E(nL);hL=Kea;iL=Lea;jL=Mea}else{var Nea=$CLJS.B(mL);$CLJS.nH(Nea,$CLJS.G([$CLJS.lu,
$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));hL=$CLJS.C(mL);iL=null;jL=0}kL=0}else break}$CLJS.nH($CLJS.CK,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PI],null)]));
for(var oL=$CLJS.z(new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.VK,null,$CLJS.OK,null,$CLJS.SK,null,$CLJS.PK,null,$CLJS.xK,null,$CLJS.rK,null,$CLJS.yK,null,$CLJS.KK,null],null),null)),pL=null,qL=0,rL=0;;)if(rL<qL){var Oea=pL.X(null,rL);$CLJS.nH(Oea,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));rL+=1}else{var sL=$CLJS.z(oL);if(sL){var tL=sL;if($CLJS.zd(tL)){var uL=$CLJS.kc(tL),Pea=$CLJS.lc(tL),Qea=uL,Rea=$CLJS.E(uL);
oL=Pea;pL=Qea;qL=Rea}else{var Sea=$CLJS.B(tL);$CLJS.nH(Sea,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));oL=$CLJS.C(tL);pL=null;qL=0}rL=0}else break}$CLJS.Y(UK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.vK,$CLJS.EK,$CLJS.JK],null));
$CLJS.pH($CLJS.LK,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UK],null)],null)],null)],null)]));
$CLJS.Y($CLJS.QK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nt,$CLJS.gG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,2,[$CLJS.vu,"valid timezone ID",$CLJS.xu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return["invalid timezone ID: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.JI($CLJS.Hd,$CLJS.qK.tz.names())),$CLJS.cK],null)],null));
$CLJS.pH($CLJS.tK,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RB,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QK],null)],null)],null)],
null)]));$CLJS.gH($CLJS.tK,$CLJS.AK);$CLJS.nH($CLJS.HK,$CLJS.G([$CLJS.lu,$CLJS.JE]));$CLJS.Y(wK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.qG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Je(function(b){return $CLJS.fE(a,b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GE,$CLJS.eF],null))}],null)],null));
$CLJS.Y(sK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gD,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wK],null)],null)],null)],null));
$CLJS.yI.g($CLJS.zK,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ts,new $CLJS.h(null,1,[$CLJS.vu,"valid :absolute-datetime clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.zK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,sK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Vs,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ts,new $CLJS.h(null,1,[$CLJS.vu,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.iK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.aK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QI],null)],null)],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ts,new $CLJS.h(null,1,[$CLJS.vu,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.vx],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MI],null)],null)],null)],null)],null)],null));
$CLJS.BG.m(null,$CLJS.zK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);a=$CLJS.F.g(b,$CLJS.vx)?$CLJS.F.g(a,$CLJS.Wh)?$CLJS.eF:$CLJS.n($CLJS.fH($CLJS.QI,a))?$CLJS.GE:$CLJS.eF:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.fh($CLJS.oK,b))?$CLJS.GE:$CLJS.n($CLJS.fh($CLJS.pK,b))?$CLJS.GE:null:null;if($CLJS.n(a))return a;b=$CLJS.CG(b);b=$CLJS.ud(b)?$CLJS.Wf.j($CLJS.Ug,$CLJS.af(function(c){return $CLJS.fE(c,$CLJS.Nj)}),b):b;return $CLJS.ud(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.Y(MK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.vx],null),$CLJS.tt],null));
$CLJS.pH($CLJS.FK,$CLJS.G([$CLJS.lu,$CLJS.eF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.My,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UF],null)],null)],null)],null)]));
$CLJS.nH($CLJS.wA,$CLJS.G([$CLJS.lu,$CLJS.EF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TF],null)]));$CLJS.Y(uK,new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.ct,$CLJS.OI,$CLJS.si,$CLJS.bk,$CLJS.BK,$CLJS.WK,$CLJS.XK,$CLJS.xj,$CLJS.yi,$CLJS.oi,$CLJS.$i,$CLJS.LI],null));$CLJS.Y(IK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.vK,$CLJS.EK,$CLJS.JK],null));
$CLJS.pH($CLJS.RK,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IK],null)],null)],
null)],null)]));