var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var U_,Bka,Cka,Dka,Eka,Fka,$_,Gka,Hka,Ika,b0,Jka,Kka,Lka,g0,h0,Mka,Nka,Oka,p0,Pka,Qka,Rka,s0,Ska,Tka,Uka,Vka;U_=function(){};$CLJS.V_=function(a){return null!=a?$CLJS.r===a.fi?!0:a.Vc?!1:$CLJS.Wa(U_,a):$CLJS.Wa(U_,a)};Bka=new $CLJS.w(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.W_=new $CLJS.K("lib","source-column-alias","lib/source-column-alias",1494821386);
Cka=new $CLJS.K("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Dka=new $CLJS.K("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.X_=new $CLJS.K("source","previous-stage","source/previous-stage",1962610878);$CLJS.Y_=new $CLJS.K(null,"database-id","database-id",1883826326);Eka=new $CLJS.w("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.Z_=new $CLJS.K("source","table-defaults","source/table-defaults",909975194);Fka=new $CLJS.K(null,"human-readable-values","human-readable-values",-624842907);$_=new $CLJS.K("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Gka=new $CLJS.K("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Hka=new $CLJS.K(null,"dbms-version","dbms-version",-69238036);
Ika=new $CLJS.K(null,"field-name","field-name",1300687948);$CLJS.a0=new $CLJS.K(null,"dataset","dataset",1159262238);b0=new $CLJS.K("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Jka=new $CLJS.K(null,"field-definitions","field-definitions",1650155116);$CLJS.c0=new $CLJS.K("source","expressions","source/expressions",-458367840);$CLJS.d0=new $CLJS.K(null,"definition","definition",-1198729982);Kka=new $CLJS.K(null,"state","state",-1988618099);
$CLJS.e0=new $CLJS.K("source","implicitly-joinable","source/implicitly-joinable",-729101197);Lka=new $CLJS.K(null,"is-audit","is-audit",327058420);$CLJS.f0=new $CLJS.K("source","joins","source/joins",1225821486);g0=new $CLJS.K("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);h0=new $CLJS.K("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);
$CLJS.i0=new $CLJS.K("lib","desired-column-alias","lib/desired-column-alias",-1998967402);Mka=new $CLJS.K("lib","external-remap","lib/external-remap",543358316);$CLJS.j0=new $CLJS.K(null,"selected?","selected?",-742502788);Nka=new $CLJS.K("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Oka=new $CLJS.K("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.k0=new $CLJS.K(null,"table-name","table-name",-2117866341);
$CLJS.l0=new $CLJS.K("source","card","source/card",-139977973);$CLJS.m0=new $CLJS.K(null,"active","active",1895962068);$CLJS.n0=new $CLJS.K(null,"dataset-query","dataset-query",1851066427);$CLJS.o0=new $CLJS.K(null,"result-metadata","result-metadata",2017934672);p0=new $CLJS.K("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Pka=new $CLJS.K(null,"details","details",1956795411);
$CLJS.q0=new $CLJS.K("source","fields","source/fields",-649667981);Qka=new $CLJS.K("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.r0=new $CLJS.K("source","native","source/native",-1444604147);Rka=new $CLJS.K("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);s0=new $CLJS.K("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.t0=new $CLJS.K("lib","card-id","lib/card-id",-1770167062);Ska=new $CLJS.K("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Tka=new $CLJS.w(null,"metadata-provider?","metadata-provider?",1669871524,null);Uka=new $CLJS.K("lib","internal-remap","lib/internal-remap",-220033801);Vka=new $CLJS.K("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.u0=new $CLJS.K(null,"source-alias","source-alias",1652088724);
$CLJS.v0=new $CLJS.K(null,"engine","engine",1459054265);$CLJS.Y(g0,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.ct,$CLJS.l0,$CLJS.r0,$CLJS.X_,$CLJS.Z_,$CLJS.q0,$CLJS.LM,$CLJS.GM,$CLJS.f0,$CLJS.c0,$CLJS.e0],null));$CLJS.Y(p0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,Vka],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pi,$CLJS.zM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AM,$CLJS.pG],null)],null));
$CLJS.Y($_,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,Ska],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pi,$CLJS.zM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$t,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.yt],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[Fka,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.yt],null)],null)],null));
$CLJS.Y($CLJS.cG,new $CLJS.Q(null,18,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.vu,"Valid column metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.rM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.Qj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gD,$CLJS.qG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pi,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.pG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bG,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.ft,$CLJS.Qj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.qG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DM,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.pG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kG,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.pG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.u0,
new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tM,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,g0],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.t0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.BM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.W_,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.gG],
null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.i0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.h(null,2,[$CLJS.Ln,1,$CLJS.Zj,60],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.j0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.xt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Mka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,p0],null)],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[Uka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$_],null)],null)],null)],null));
$CLJS.Y(s0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.k0,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[Jka,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[Ika,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gD,$CLJS.qG],null)],null)],null)],null)],null)],null));
$CLJS.Y(b0,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.m0,$CLJS.xt],null),new $CLJS.Q(null,2,5,$CLJS.R,[Kka,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.k0,$CLJS.gG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.d0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,s0],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qM,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,
$CLJS.gG],null)],null)],null));
$CLJS.Y(Qka,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.vu,"Valid Card metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.KM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pi,$CLJS.BM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Y_,$CLJS.CM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.n0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.Yj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.o0,
new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.Yj],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.a0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.xt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EM,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.wM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Dka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,b0],null)],null)],null)],null));
$CLJS.Y(Nka,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.vu,"Valid Segment metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.vM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pi,$CLJS.uM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EM,$CLJS.wM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.d0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.Yj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Su,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.gG],null)],null)],null));
$CLJS.Y(Oka,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.vu,"Valid Metric metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.xM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pi,$CLJS.sM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EM,$CLJS.wM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.d0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.Yj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Su,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.gG],null)],null)],null));
$CLJS.Y(Rka,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.vu,"Valid Table metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.yM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pi,$CLJS.wM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bG,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cl,new $CLJS.h(null,
1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.gG],null)],null)],null));
$CLJS.Y(Cka,new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.vu,"Valid Database metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.IM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pi,$CLJS.CM],null),new $CLJS.Q(null,3,5,$CLJS.R,[Hka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.Yj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Pka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.Yj],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.v0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.Ni],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XB,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qi,$CLJS.Ni],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Lka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.xt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FM,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.Yj],null)],null)],null));
$CLJS.Y(h0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid MetadataProvider"],null),new $CLJS.Fc(function(){return $CLJS.V_},Eka,$CLJS.Ag([$CLJS.Mi,$CLJS.T,$CLJS.ek,$CLJS.ni,$CLJS.Ki,$CLJS.ej,$CLJS.lk,$CLJS.mi,$CLJS.U,$CLJS.dk],[Bka,Tka,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.W(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Mq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.V_)?$CLJS.V_.H:null]))],null));
$CLJS.Y(Gka,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,h0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.vu,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,h0],null)],null)],null)],null));