var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var boa=function(a){return function(b){var c=$CLJS.Re(-1);return function(){function d(l,m){var t=c.Cd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=
e;k.g=d;return k}()}},coa=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.gD);return $CLJS.Je(function(c){return $CLJS.fE(b,c)},$CLJS.aH)},W6=new $CLJS.K("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.gH($CLJS.VH,W6);$CLJS.gH($CLJS.OH,W6);
$CLJS.S1.m(null,$CLJS.pT,function(a,b){var c=$CLJS.De($CLJS.pT.h($CLJS.gY(a,b)));return $CLJS.n(c)?$CLJS.XF("Sorted by {0}",$CLJS.G([$CLJS.e3($CLJS.zG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.r1.v(a,b,v,$CLJS.s1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);
return $CLJS.ce($CLJS.r1.v(a,b,t,$CLJS.s1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.P1.m(null,W6,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.r1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "asc":return $CLJS.XF("{0} ascending",$CLJS.G([a]));case "desc":return $CLJS.XF("{0} descending",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.W1.m(null,W6,function(a,b,c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.S.j($CLJS.u1.j(a,b,c),$CLJS.D1,d)});var X6,doa=$CLJS.Oe($CLJS.N),eoa=$CLJS.Oe($CLJS.N),foa=$CLJS.Oe($CLJS.N),goa=$CLJS.Oe($CLJS.N),hoa=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.Zi,$CLJS.UD],null),$CLJS.Zi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));X6=new $CLJS.Xh($CLJS.xh.g("metabase.lib.order-by","order-by-clause-method"),$CLJS.SD,hoa,doa,eoa,foa,goa);X6.m(null,W6,function(a){return $CLJS.RX(a)});
X6.m(null,$CLJS.Wh,function(a){if(null==a)throw $CLJS.ai($CLJS.zG("Can''t order by nil"),$CLJS.N);return $CLJS.RX(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VH,$CLJS.SX(a)],null))});
$CLJS.ioa=function(){function a(d,e){d=X6.h(d);e=$CLJS.n(e)?e:$CLJS.VH;return $CLJS.S.j($CLJS.uf(d),0,e)}function b(d){return c.g?c.g(d,$CLJS.VH):c.call(null,d,$CLJS.VH)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y6=function(){function a(e,f,k,l){f=$CLJS.n(f)?f:-1;var m=function(){var t=X6.h(k);return $CLJS.n(l)?$CLJS.S.j($CLJS.uf(t),0,l):t}();return $CLJS.pY.l(e,f,$CLJS.Fk,$CLJS.G([$CLJS.pT,function(t){return $CLJS.$d.g($CLJS.uf(t),m)}]))}function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.v?d.v(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,
e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();$CLJS.Z6=function(){function a(d,e){return $CLJS.De($CLJS.I.g($CLJS.gY(d,e),$CLJS.pT))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.$6=function(){function a(d,e){var f=$CLJS.De($CLJS.U5(d,e)),k=$CLJS.De($CLJS.g5.g(d,e));f=$CLJS.n($CLJS.n(f)?f:k)?$CLJS.Ye.g(f,k):function(){var t=$CLJS.gY(d,e);return $CLJS.$1.v(d,e,t,new $CLJS.h(null,1,[$CLJS.L1,!1],null))}();var l=$CLJS.bf(coa,f);f=$CLJS.Ze.g(function(t){$CLJS.H(t,0,null);$CLJS.H(t,1,null);return $CLJS.H(t,2,null)},$CLJS.Z6.g(d,e));if($CLJS.sd(l))return null;if($CLJS.sd(f))return $CLJS.uf(l);var m=$CLJS.Wf.j($CLJS.N,$CLJS.Ck.g($CLJS.Ze.h($CLJS.SX),boa(function(t,u){u=$CLJS.V2.v(d,
e,u,l);return $CLJS.n(u)?new $CLJS.Q(null,2,5,$CLJS.R,[u,t],null):null})),f);return $CLJS.Ek.g(function(t){var u=m.h?m.h(t):m.call(null,t);return $CLJS.n(u)?$CLJS.S.j(t,$CLJS.B1,u):t},l)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.a7=new $CLJS.h(null,2,[$CLJS.VH,$CLJS.OH,$CLJS.OH,$CLJS.VH],null);