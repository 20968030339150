var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.vL=new $CLJS.K("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.wL=new $CLJS.K("operator","filter","operator/filter",-1518842858);$CLJS.xL=new $CLJS.K(null,"display-name-variant","display-name-variant",-1831788853);for(var yL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nt,$CLJS.gt],null)),zL=null,AL=0,BL=0;;)if(BL<AL){var Tea=zL.X(null,BL);$CLJS.pH(Tea,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Ln,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)],null)],null)]));BL+=1}else{var CL=$CLJS.z(yL);if(CL){var DL=CL;if($CLJS.zd(DL)){var EL=$CLJS.kc(DL),Uea=$CLJS.lc(DL),
Vea=EL,Wea=$CLJS.E(EL);yL=Uea;zL=Vea;AL=Wea}else{var Xea=$CLJS.B(DL);$CLJS.pH(Xea,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Ln,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)],null)],null)]));yL=$CLJS.C(DL);zL=null;AL=0}BL=0}else break}$CLJS.nH($CLJS.it,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)]));
for(var FL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.pI],null)),GL=null,HL=0,IL=0;;)if(IL<HL){var Yea=GL.X(null,IL);$CLJS.pH(Yea,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Ln,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null)],null)],null)],null)]));IL+=1}else{var JL=$CLJS.z(FL);if(JL){var KL=JL;if($CLJS.zd(KL)){var LL=$CLJS.kc(KL),Zea=$CLJS.lc(KL),
$ea=LL,afa=$CLJS.E(LL);FL=Zea;GL=$ea;HL=afa}else{var bfa=$CLJS.B(KL);$CLJS.pH(bfa,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Ln,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null)],null)],null)],null)]));FL=$CLJS.C(KL);GL=null;HL=0}IL=0}else break}
for(var ML=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ds,$CLJS.Fs,$CLJS.zs,$CLJS.Bs],null)),NL=null,OL=0,PL=0;;)if(PL<OL){var cfa=NL.X(null,PL);$CLJS.nH(cfa,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)]));PL+=1}else{var QL=$CLJS.z(ML);if(QL){var RL=QL;if($CLJS.zd(RL)){var SL=$CLJS.kc(RL),dfa=$CLJS.lc(RL),efa=SL,ffa=$CLJS.E(SL);ML=dfa;NL=efa;OL=ffa}else{var gfa=$CLJS.B(RL);$CLJS.nH(gfa,$CLJS.G([$CLJS.lu,$CLJS.Cj,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)]));ML=$CLJS.C(RL);NL=null;OL=0}PL=0}else break}$CLJS.nH($CLJS.zH,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)]));
$CLJS.nH($CLJS.FH,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)]));
for(var TL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yH,$CLJS.KH],null)),UL=null,VL=0,WL=0;;)if(WL<VL){var hfa=UL.X(null,WL);$CLJS.nH(hfa,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null)]));WL+=1}else{var XL=$CLJS.z(TL);if(XL){var YL=XL;if($CLJS.zd(YL)){var ZL=$CLJS.kc(YL),ifa=$CLJS.lc(YL),jfa=ZL,kfa=$CLJS.E(ZL);TL=ifa;UL=jfa;VL=kfa}else{var lfa=$CLJS.B(YL);$CLJS.nH(lfa,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null)]));TL=$CLJS.C(YL);
UL=null;VL=0}WL=0}else break}
for(var $L=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AH,$CLJS.HH],null)),aM=null,bM=0,cM=0;;)if(cM<bM){var mfa=aM.X(null,cM);$CLJS.nH(mfa,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WG],null)]));cM+=1}else{var dM=$CLJS.z($L);if(dM){var eM=dM;if($CLJS.zd(eM)){var fM=$CLJS.kc(eM),nfa=$CLJS.lc(eM),ofa=fM,pfa=$CLJS.E(fM);$L=nfa;aM=ofa;bM=pfa}else{var qfa=$CLJS.B(eM);$CLJS.nH(qfa,$CLJS.G([$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WG],null)]));$L=$CLJS.C(eM);
aM=null;bM=0}cM=0}else break}
for(var gM=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fI,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.xt],null)],null),hM=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.PH,$CLJS.iI,$CLJS.XH,$CLJS.QH],null)),iM=null,jM=0,kM=0;;)if(kM<jM){var lM=iM.X(null,kM);$CLJS.yI.v(lM,$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,lM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,$CLJS.mG,gM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.XG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null));kM+=1}else{var mM=$CLJS.z(hM);if(mM){var nM=mM;if($CLJS.zd(nM)){var oM=$CLJS.kc(nM),rfa=$CLJS.lc(nM),sfa=oM,tfa=$CLJS.E(oM);hM=rfa;iM=sfa;jM=tfa}else{var pM=$CLJS.B(nM);$CLJS.yI.v(pM,$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,pM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,$CLJS.mG,gM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.XG],null)],null));hM=$CLJS.C(nM);iM=null;jM=0}kM=0}else break}
$CLJS.yI.v($CLJS.jI,$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.jI],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,$CLJS.mG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mI,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.xt],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.vx,$CLJS.Mz,$CLJS.EH],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.$G],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UF],null)],null));$CLJS.yI.v($CLJS.vH,$CLJS.lu,$CLJS.Cj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.vH],null),$CLJS.mG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.ZF,$CLJS.gG],null)],null));
$CLJS.Y($CLJS.vL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.wL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RH,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.ct,$CLJS.Gs,$CLJS.pI,$CLJS.FH,$CLJS.zH,$CLJS.Ds,$CLJS.zs,$CLJS.Fs,$CLJS.Bs,$CLJS.yH,$CLJS.KH,$CLJS.AH,$CLJS.HH,$CLJS.XH,$CLJS.QH,$CLJS.PH,$CLJS.iI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xL,$CLJS.Ni],null)],null));