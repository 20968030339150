var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var uB=function(){},vB=function(a){return $CLJS.I.g($CLJS.jA,a).value},Eba=function(a){var b=vB(a);return $CLJS.n(function(){var c=vB($CLJS.AA);return $CLJS.xk.g?$CLJS.xk.g(c,b):$CLJS.xk.call(null,c,b)}())?$CLJS.fB:$CLJS.n(function(){var c=vB($CLJS.zA);return $CLJS.xk.g?$CLJS.xk.g(c,b):$CLJS.xk.call(null,c,b)}())?$CLJS.pB:$CLJS.n(function(){var c=vB($CLJS.xA);return $CLJS.xk.g?$CLJS.xk.g(c,b):$CLJS.xk.call(null,c,b)}())?$CLJS.iB:$CLJS.n(function(){var c=vB($CLJS.rA);return $CLJS.xk.g?
$CLJS.xk.g(c,b):$CLJS.xk.call(null,c,b)}())?$CLJS.oB:$CLJS.n(function(){var c=vB($CLJS.yA);return $CLJS.xk.g?$CLJS.xk.g(c,b):$CLJS.xk.call(null,c,b)}())?$CLJS.qB:$CLJS.n(function(){var c=vB($CLJS.uA);return $CLJS.xk.g?$CLJS.xk.g(c,b):$CLJS.xk.call(null,c,b)}())?$CLJS.lB:$CLJS.n(function(){var c=vB($CLJS.oA);return $CLJS.xk.g?$CLJS.xk.g(c,b):$CLJS.xk.call(null,c,b)}())?$CLJS.mB:$CLJS.nB},wB=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},xB=function(a,b,c){var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.$d.l(a,["color:",$CLJS.p.h($CLJS.I.g($CLJS.tB,c))].join(""),$CLJS.G(["color:black"]))],null)},yB=function(a,b,c){var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.$d.l(a,["color:",$CLJS.p.h($CLJS.I.g($CLJS.tB,$CLJS.rB)),";background-color:",$CLJS.p.h($CLJS.I.g($CLJS.tB,
c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],null)},Fba=function(a){function b(c,d){return d>=c}a=vB(a);if(b(vB($CLJS.AA),a))return"error";if(b(vB($CLJS.zA),a))return"warn";if(b(vB($CLJS.xA),a))return"info";b(vB($CLJS.rA),a);return"log"},zB=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.I.g(c,$CLJS.pA),e=$CLJS.I.g(c,$CLJS.Qu);b=$CLJS.I.g(c,$CLJS.BA);e=Fba(e);e=$CLJS.Ba(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.P.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=["[",
$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},AB=function AB(a,b){for(;;){if($CLJS.F.g($CLJS.eB,b))return xB(a,", ",$CLJS.nB);if($CLJS.F.g($CLJS.gB,b))return wB(a);if(b instanceof $CLJS.K)return xB(a,b,$CLJS.iB);if(b instanceof $CLJS.w)return xB(a,b,$CLJS.oB);if("string"===typeof b)return xB(a,$CLJS.Eh.l($CLJS.G([b])),$CLJS.hB);if($CLJS.qf(b)){var d=wB(function(){var f=a,k=$CLJS.yb(b);return AB.g?AB.g(f,k):AB.call(null,f,k)}()),e=$CLJS.zb(b);
return AB.g?AB.g(d,e):AB.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.zg)return d=a,d=xB(d,"{",$CLJS.sB),d=$CLJS.ab(AB,d,$CLJS.Xe($CLJS.eB,b)),xB(d,"}",$CLJS.sB);if($CLJS.wd(b))return d=a,d=xB(d,["#",$CLJS.p.h(function(){var f=$CLJS.Xa(b),k=f.name;return $CLJS.sd(k)?$CLJS.Eh.l($CLJS.G([f])):k}())," "].join(""),$CLJS.jB),d=xB(d,"{",$CLJS.sB),d=$CLJS.ab(AB,d,$CLJS.Xe($CLJS.eB,b)),xB(d,"}",$CLJS.sB);if($CLJS.ud(b))return d=a,d=xB(d,"#{",$CLJS.sB),d=$CLJS.ab(AB,d,$CLJS.Xe($CLJS.gB,b)),xB(d,
"}",$CLJS.sB);if($CLJS.yd(b))return d=a,d=xB(d,"[",$CLJS.sB),d=$CLJS.ab(AB,d,$CLJS.Xe($CLJS.gB,b)),xB(d,"]",$CLJS.sB);if(b instanceof $CLJS.If)d=xB(a,"#queue ",$CLJS.jB),e=$CLJS.Wf.g($CLJS.tf,b),a=d,b=e;else{if($CLJS.Cd(b))return d=a,d=xB(d,"(",$CLJS.jB),d=$CLJS.ab(AB,d,$CLJS.Xe($CLJS.gB,b)),xB(d,")",$CLJS.jB);if(null!=b?b.I&16384||$CLJS.r===b.Ui||(b.I?0:$CLJS.Wa(uB,b)):$CLJS.Wa(uB,b))d=xB(a,"#atom ",$CLJS.jB),e=$CLJS.q(b),a=d,b=e;else if($CLJS.Yh(b))d=xB(a,"#uuid ",$CLJS.jB),e=$CLJS.p.h(b),a=d,b=
e;else if($CLJS.Ua(b))d=xB(a,"#js ",$CLJS.jB),e=$CLJS.ab(function(f,k){return function(l,m){return $CLJS.S.j(l,$CLJS.rh.h(m),$CLJS.Ba(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ra(b))d=xB(a,"#js ",$CLJS.jB),e=$CLJS.Wf.g($CLJS.tf,b),a=d,b=e;else return xB(a,$CLJS.Eh.l($CLJS.G([b])),$CLJS.kB)}}};$CLJS.Gba=zB(function(a){a=$CLJS.O(a);$CLJS.I.g(a,$CLJS.Qu);var b=$CLJS.I.g(a,$CLJS.pA),c=$CLJS.I.g(a,$CLJS.Ii);$CLJS.I.g(a,$CLJS.BA);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.Hba=zB(function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.Qu);a=$CLJS.I.g(b,$CLJS.pA);var d=$CLJS.I.g(b,$CLJS.Ii);$CLJS.I.g(b,$CLJS.BA);b=Eba(c);d=AB(wB(yB(yB(yB(new $CLJS.Q(null,2,5,$CLJS.R,["",$CLJS.tf],null),"[",b),a,b),"]",b)),d);a=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.ce(a,d)});
$CLJS.Iba=zB(function(a){a=$CLJS.O(a);$CLJS.I.g(a,$CLJS.Qu);var b=$CLJS.I.g(a,$CLJS.pA),c=$CLJS.I.g(a,$CLJS.Ii);$CLJS.I.g(a,$CLJS.BA);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Eh.l($CLJS.G([c]))],null)});