var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var j9,l9,k9,ita,r9,s9,jta,kta,lta,m9,mta,x9,nta,ota,pta;j9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Ba(a,b):null};l9=function(a,b){return k9(a,b,$CLJS.N)};
k9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.I.j(c,m9,!0);if($CLJS.wd(b))return $CLJS.Wf.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=j9(b,"_plainObject");d=null==d?null:$CLJS.EB(d);return null==d?null:$CLJS.De(d)}():null;return $CLJS.n(c)?$CLJS.Wf.j($CLJS.N,a,c):$CLJS.Wf.j($CLJS.N,$CLJS.Ck.j($CLJS.Ze.h(function(d){return new $CLJS.Q(null,2,5,$CLJS.R,[d,j9(b,d)],null)}),$CLJS.Dk.h(function(d){$CLJS.H(d,0,null);return"function"===typeof $CLJS.H(d,1,null)}),a),$CLJS.Aa(b))};
ita=function(a){var b=n9.h(a),c=o9.h(a),d=p9.h(a);return $CLJS.Ck.j($CLJS.Ze.h(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);var k=$CLJS.R;f=$CLJS.rh.h((0,$CLJS.SC)(f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.Q(null,2,5,k,[f,e],null)}),$CLJS.sd(b)?$CLJS.Rd:$CLJS.Dk.h(function(e){var f=$CLJS.H(e,0,null);$CLJS.H(e,1,null);return $CLJS.Gd(b,f)}),$CLJS.Sa(c)?$CLJS.Rd:$CLJS.Ze.h(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return new $CLJS.Q(null,
2,5,$CLJS.R,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};
r9=function(a,b){var c=ita(a),d=q9.h(a);return function(e){try{var f=$CLJS.S.j(k9(c,e,b),$CLJS.vj,d),k=$CLJS.zC($CLJS.sA);$CLJS.n($CLJS.yC("metabase.lib.js.metadata",k))&&$CLJS.xC("metabase.lib.js.metadata",k,$CLJS.WX("Parsed metadata %s %s\n%s",$CLJS.G([a,$CLJS.Pi.h(f),$CLJS.XX(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.zC($CLJS.tA),$CLJS.n($CLJS.yC("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.xC("metabase.lib.js.metadata",k,$CLJS.WX("Error parsing %s %s: %s",
$CLJS.G([a,$CLJS.Eh.l($CLJS.G([e])),$CLJS.UW(f)])),f):$CLJS.xC("metabase.lib.js.metadata",k,$CLJS.WX(f,$CLJS.G(["Error parsing %s %s: %s",a,$CLJS.Eh.l($CLJS.G([e])),$CLJS.UW(f)])),null)),null;throw l;}}};s9=function(a){return $CLJS.Ek.g(r9($CLJS.YH,$CLJS.N),a)};jta=new $CLJS.K(null,"plain-object","plain-object",-943264281);$CLJS.t9=new $CLJS.K(null,"cards","cards",169174038);kta=new $CLJS.K(null,"db","db",993250759);$CLJS.u9=new $CLJS.K(null,"metrics","metrics",394093469);
lta=new $CLJS.K(null,"fks","fks",2041987076);$CLJS.v9=new $CLJS.K(null,"segments","segments",1937535949);m9=new $CLJS.K(null,"use-plain-object?","use-plain-object?",-1174595475);mta=new $CLJS.K(null,"metadata","metadata",1799301597);$CLJS.w9=new $CLJS.K(null,"tables","tables",1334623052);x9=new $CLJS.K(null,"dimension-options","dimension-options",2122379680);nta=new $CLJS.K(null,"schema-name","schema-name",1666725119);ota=new $CLJS.K(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);
pta=new $CLJS.K(null,"default-dimension-option","default-dimension-option",149055958);$CLJS.y9=new $CLJS.K(null,"databases","databases",2040134125);var n9,qta=$CLJS.Oe($CLJS.N),rta=$CLJS.Oe($CLJS.N),sta=$CLJS.Oe($CLJS.N),tta=$CLJS.Oe($CLJS.N),uta=$CLJS.I.j($CLJS.N,$CLJS.Zi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null)),A9,Kta,Lta,Mta,Nta,Ota;n9=new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.rh,uta,qta,rta,sta,tta);n9.m(null,$CLJS.Wh,function(){return null});var o9,vta=$CLJS.Oe($CLJS.N),wta=$CLJS.Oe($CLJS.N),xta=$CLJS.Oe($CLJS.N),yta=$CLJS.Oe($CLJS.N),zta=$CLJS.I.j($CLJS.N,$CLJS.Zi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
o9=new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.rh,zta,vta,wta,xta,yta);o9.m(null,$CLJS.Wh,function(){return null});var q9,Ata=$CLJS.Oe($CLJS.N),Bta=$CLJS.Oe($CLJS.N),Cta=$CLJS.Oe($CLJS.N),Dta=$CLJS.Oe($CLJS.N),Eta=$CLJS.I.j($CLJS.N,$CLJS.Zi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));q9=new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","lib-type"),$CLJS.rh,Eta,Ata,Bta,Cta,Dta);
var p9,Fta=$CLJS.Oe($CLJS.N),Gta=$CLJS.Oe($CLJS.N),Hta=$CLJS.Oe($CLJS.N),Ita=$CLJS.Oe($CLJS.N),Jta=$CLJS.I.j($CLJS.N,$CLJS.Zi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));p9=new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Rd,Jta,Fta,Gta,Hta,Ita);p9.m(null,$CLJS.Wh,function(){return null});
$CLJS.z9=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.Zi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.rh.h(f)},e,a,b,c,d)}();Kta=$CLJS.Oe($CLJS.N);Lta=$CLJS.Oe($CLJS.N);Mta=$CLJS.Oe($CLJS.N);Nta=$CLJS.Oe($CLJS.N);Ota=$CLJS.I.j($CLJS.N,$CLJS.Zi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
A9=new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.rh,Ota,Kta,Lta,Mta,Nta);$CLJS.z9.m(null,$CLJS.Wh,function(a,b){var c=r9(a,$CLJS.N);return l9($CLJS.Ze.h(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VX(e),new $CLJS.Bh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),j9(b,A9.h(a)))});q9.m(null,$CLJS.vR,function(){return $CLJS.IM});
n9.m(null,$CLJS.vR,function(){return new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.HG,null,$CLJS.w9,null],null),null)});o9.m(null,$CLJS.vR,function(){return function(a,b){switch(a instanceof $CLJS.K?a.T:null){case "dbms-version":return $CLJS.DB(b,$CLJS.G([$CLJS.ri,!0]));case "features":return $CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h($CLJS.rh),b);case "native-permissions":return $CLJS.rh.h(b);default:return b}}});A9.m(null,$CLJS.vR,function(){return"databases"});q9.m(null,$CLJS.oY,function(){return $CLJS.yM});
n9.m(null,$CLJS.oY,function(){return new $CLJS.Tg(null,new $CLJS.h(null,5,[x9,null,$CLJS.HG,null,$CLJS.v9,null,$CLJS.vR,null,$CLJS.u9,null],null),null)});o9.m(null,$CLJS.oY,function(){return function(a,b){switch(a instanceof $CLJS.K?a.T:null){case "entity-type":return $CLJS.rh.h(b);case "field-order":return $CLJS.rh.h(b);case "initial-sync-status":return $CLJS.rh.h(b);case "visibility-type":return $CLJS.rh.h(b);default:return b}}});
$CLJS.z9.m(null,$CLJS.oY,function(a,b){var c=r9(a,$CLJS.N);return l9($CLJS.Ck.g($CLJS.Dk.h(function(d){var e=$CLJS.H(d,0,null);$CLJS.H(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.Ze.h(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VX(e),new $CLJS.Bh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),j9(b,"tables"))});q9.m(null,$CLJS.YH,function(){return $CLJS.rM});
n9.m(null,$CLJS.YH,function(){return new $CLJS.Tg(null,new $CLJS.h(null,7,[x9,null,$CLJS.oY,null,$CLJS.aN,null,pta,null,$CLJS.vR,null,ota,null,$CLJS.u9,null],null),null)});p9.m(null,$CLJS.YH,function(){return new $CLJS.h(null,1,[$CLJS.RB,$CLJS.tM],null)});
o9.m(null,$CLJS.YH,function(){return function(a,b){switch(a instanceof $CLJS.K?a.T:null){case "base-type":return $CLJS.rh.h(b);case "coercion-strategy":return $CLJS.rh.h(b);case "effective-type":return $CLJS.rh.h(b);case "fingerprint":return $CLJS.wd(b)?$CLJS.XW(b):$CLJS.DB(b,$CLJS.G([$CLJS.ri,!0]));case "has-field-values":return $CLJS.rh.h(b);case "lib/source":return $CLJS.F.g(b,"aggregation")?$CLJS.LM:$CLJS.rh.g("source",b);case "semantic-type":return $CLJS.rh.h(b);case "visibility-type":return $CLJS.rh.h(b);
case "id":return $CLJS.yd(b)?$CLJS.hd(b):b;default:return b}}});
$CLJS.z9.m(null,$CLJS.YH,function(a,b){var c=r9(a,$CLJS.N),d=j9(b,"fields");return l9($CLJS.Jl(function(e){var f=$CLJS.H(e,0,null),k=$CLJS.H(e,1,null);e=function(){var l=$CLJS.VX(f);if($CLJS.n(l))return l;l=$CLJS.fh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.H(l,0,null);l=$CLJS.H(l,1,null);var m=null==j9(d,l);return m?$CLJS.VX(l):m}return null}();return $CLJS.n(e)?new $CLJS.Q(null,2,5,$CLJS.R,[e,new $CLJS.Bh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});q9.m(null,$CLJS.XO,function(){return $CLJS.KM});
n9.m(null,$CLJS.XO,function(){return new $CLJS.Tg(null,new $CLJS.h(null,11,[x9,null,$CLJS.cl,null,lta,null,$CLJS.oY,null,kta,null,jta,null,$CLJS.v9,null,$CLJS.vR,null,mta,null,$CLJS.u9,null,nta,null],null),null)});
o9.m(null,$CLJS.XO,function(){return function(a,b){switch(a instanceof $CLJS.K?a.T:null){case "result-metadata":return $CLJS.n($CLJS.pC($CLJS.vd,$CLJS.Ra)(b))?s9(b):$CLJS.DB(b,$CLJS.G([$CLJS.ri,!0]));case "fields":return s9(b);case "visibility-type":return $CLJS.rh.h(b);case "dataset-query":return $CLJS.DB(b,$CLJS.G([$CLJS.ri,!0]));case "dataset":return b;default:return b}}});
$CLJS.z9.m(null,$CLJS.XO,function(a,b){return $CLJS.Wf.j($CLJS.N,$CLJS.Ze.h(function(c){return new $CLJS.Q(null,2,5,$CLJS.R,[c,new $CLJS.Bh(function(){var d=r9($CLJS.XO,new $CLJS.h(null,1,[m9,!1],null)),e=r9($CLJS.XO,$CLJS.N),f=$CLJS.Uk.l;d=$CLJS.S.j(d(j9(j9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.Pi,c);var k=j9(j9(b,"questions"),$CLJS.p.h(c));var l=j9(k,"_card");k=$CLJS.n(l)?l:k;e=e.h?e.h(k):e.call(null,k);return f.call($CLJS.Uk,$CLJS.G([d,e]))})],null)}),$CLJS.Wf.j($CLJS.Wf.j($CLJS.Ug,
$CLJS.Jl($CLJS.hY),$CLJS.Aa(j9(b,"tables"))),$CLJS.Ze.h($CLJS.VX),$CLJS.Aa(j9(b,"questions"))))});q9.m(null,$CLJS.TH,function(){return $CLJS.xM});n9.m(null,$CLJS.TH,function(){return new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.oY,null,$CLJS.vR,null],null),null)});o9.m(null,$CLJS.TH,function(){return function(a,b){return b}});A9.m(null,$CLJS.TH,function(){return"metrics"});q9.m(null,$CLJS.vH,function(){return $CLJS.vM});
n9.m(null,$CLJS.vH,function(){return new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.oY,null,$CLJS.vR,null],null),null)});o9.m(null,$CLJS.vH,function(){return function(a,b){return b}});A9.m(null,$CLJS.vH,function(){return"segments"});$CLJS.B9=r9($CLJS.YH,$CLJS.N);