var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var hJ,iJ,Pda,kJ,Qda,Rda,Sda,lJ,jJ;$CLJS.fJ=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.$d.g($CLJS.I.j(c,e,$CLJS.tf),d))},$CLJS.cc($CLJS.N),b))};$CLJS.gJ=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Rc(d):null},null,b)};
hJ=function(a,b){$CLJS.H(a,0,null);$CLJS.H(a,1,null);$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);b=$CLJS.fE(b,$CLJS.GE)?$CLJS.SI:$CLJS.fE(b,$CLJS.EF)?$CLJS.TF:$CLJS.fE(b,$CLJS.eF)?$CLJS.UF:null;return $CLJS.n(b)?$CLJS.fH(b,a):!0};
iJ=function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.fJ(function(d){return $CLJS.fE($CLJS.CG(d),$CLJS.RE)},a);var b=$CLJS.O(a);a=$CLJS.I.g(b,!1);b=$CLJS.I.g(b,!0);if($CLJS.Ak.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.CG($CLJS.B(a));return $CLJS.Je(function(d){$CLJS.H(d,0,null);$CLJS.H(d,1,null);$CLJS.H(d,2,null);
var e=$CLJS.H(d,3,null);return $CLJS.n(hJ(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Pda=function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.nt,new $CLJS.h(null,1,[$CLJS.vu,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Ts,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jJ],null)],
null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.xu,function(b){b=$CLJS.O(b);b=$CLJS.I.g(b,$CLJS.uj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(iJ(b))].join("")}],null),$CLJS.Ke(iJ)],null)],null)};
kJ=function(a){var b=$CLJS.R,c=Pda(a);a=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ts,new $CLJS.h(null,1,[$CLJS.vu,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Ln,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)],null)],null);
return new $CLJS.Q(null,3,5,b,[$CLJS.gt,c,a],null)};Qda=function(a){return $CLJS.Od($CLJS.iH,$CLJS.Ze.g(function(b){var c=$CLJS.CG(b),d=$CLJS.fE(c,$CLJS.OG);b=d?$CLJS.fH($CLJS.bI,b):d;return $CLJS.n(b)?$CLJS.Gj:c},a))};Rda=function(a){a=$CLJS.gJ(function(b){return!$CLJS.fE(b,$CLJS.RE)},$CLJS.Ze.g($CLJS.CG,a));return $CLJS.fE(a,$CLJS.OG)?$CLJS.Nj:a};Sda=function(a){return $CLJS.n($CLJS.Je(function(b){return $CLJS.fE($CLJS.CG(b),$CLJS.RE)},a))?Rda(a):Qda(a)};
lJ=new $CLJS.K("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.mJ=new $CLJS.K("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);jJ=new $CLJS.K("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.K("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ws,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.K(null,"intervals","intervals",2096054013),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,jJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.CG(a);return $CLJS.Ie(function(d){return hJ(d,c)},b)}],null)],null));
$CLJS.Y(lJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Ln,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)],null));$CLJS.yI.g($CLJS.Js,kJ($CLJS.Js));$CLJS.yI.g($CLJS.lu,kJ($CLJS.lu));$CLJS.pH($CLJS.Ks,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,lJ],null)]));$CLJS.pH($CLJS.tI,$CLJS.G([$CLJS.lu,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,lJ],null)]));
for(var nJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Js,$CLJS.lu,$CLJS.Ks],null)),oJ=null,pJ=0,qJ=0;;)if(qJ<pJ){var Tda=oJ.X(null,qJ);$CLJS.gH(Tda,$CLJS.mJ);qJ+=1}else{var rJ=$CLJS.z(nJ);if(rJ){var sJ=rJ;if($CLJS.zd(sJ)){var tJ=$CLJS.kc(sJ),Uda=$CLJS.lc(sJ),Vda=tJ,Wda=$CLJS.E(tJ);nJ=Uda;oJ=Vda;pJ=Wda}else{var Xda=$CLJS.B(sJ);$CLJS.gH(Xda,$CLJS.mJ);nJ=$CLJS.C(sJ);oJ=null;pJ=0}qJ=0}else break}$CLJS.BG.m(null,$CLJS.mJ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return Sda(a)});
$CLJS.nH($CLJS.xH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.gH($CLJS.xH,$CLJS.TG);
for(var uJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vI,$CLJS.qH,$CLJS.LH],null)),vJ=null,wJ=0,xJ=0;;)if(xJ<wJ){var Yda=vJ.X(null,xJ);$CLJS.nH(Yda,$CLJS.G([$CLJS.lu,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));xJ+=1}else{var yJ=$CLJS.z(uJ);if(yJ){var zJ=yJ;if($CLJS.zd(zJ)){var AJ=$CLJS.kc(zJ),Zda=$CLJS.lc(zJ),$da=AJ,aea=$CLJS.E(AJ);uJ=Zda;vJ=$da;wJ=aea}else{var bea=$CLJS.B(zJ);$CLJS.nH(bea,$CLJS.G([$CLJS.lu,$CLJS.BF,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));uJ=$CLJS.C(zJ);vJ=null;wJ=0}xJ=0}else break}
for(var BJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tH,$CLJS.WH,$CLJS.cI],null)),CJ=null,DJ=0,EJ=0;;)if(EJ<DJ){var cea=CJ.X(null,EJ);$CLJS.nH(cea,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));EJ+=1}else{var FJ=$CLJS.z(BJ);if(FJ){var GJ=FJ;if($CLJS.zd(GJ)){var HJ=$CLJS.kc(GJ),dea=$CLJS.lc(GJ),eea=HJ,fea=$CLJS.E(HJ);BJ=dea;CJ=eea;DJ=fea}else{var gea=$CLJS.B(GJ);$CLJS.nH(gea,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));BJ=$CLJS.C(GJ);CJ=null;DJ=0}EJ=0}else break}$CLJS.nH($CLJS.uH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));
$CLJS.BG.m(null,$CLJS.uH,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.fE($CLJS.CG(b),$CLJS.nj)&&$CLJS.fE($CLJS.CG(a),$CLJS.nj)?$CLJS.nj:$CLJS.BF});