var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var DW,EW,FW,HW,IW,JW,KW,LW,MW,NW;DW=new $CLJS.K("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);EW=new $CLJS.K("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);FW=new $CLJS.K("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.GW=new $CLJS.K("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);HW=new $CLJS.K("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);IW=new $CLJS.K("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);JW=new $CLJS.K("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
KW=new $CLJS.K("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);LW=new $CLJS.K("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);MW=new $CLJS.K("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);NW=new $CLJS.K("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(DW,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid template tag :widget-type"],null),$CLJS.WB],null),$CLJS.Pg($CLJS.yW)));$CLJS.Y(HW,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ct,$CLJS.gO,$CLJS.XO,$CLJS.KN,$CLJS.ev,$CLJS.kD,$CLJS.cR],null));
$CLJS.Y(KW,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bG,$CLJS.gG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pi,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.gG,$CLJS.wt],null)],null)],null));
$CLJS.Y(LW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.pl],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yP,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.xt],null)],null)],null));
$CLJS.Y(IW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LW],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.KN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rP,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Xi,new $CLJS.h(null,1,[$CLJS.Et,!0],
null),$CLJS.Yj],null)],null)],null));
$CLJS.Y(JW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KW],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.gO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TN,$CLJS.gG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uR,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.JM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vR,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.CM],null)],null)],null));
$CLJS.Y(NW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.XO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ES,$CLJS.BM],null)],null)],null));$CLJS.Y(MW,$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ct],null),$CLJS.mW));
$CLJS.Y(EW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MW],null)],null)],null)],null));
$CLJS.Y(FW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HW],null)],null)],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.Gi,$CLJS.dj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gO,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XO,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mo,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EW],null)],null)],null)],null));
$CLJS.Y($CLJS.GW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,$CLJS.gG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Ie(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.T.h(b))},a)}],null)],null));