var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Ada,Bda,Cda,Dda,Eda,Fda,Gda,Hda,Ida;Ada=function(a,b){a.sort(b||$CLJS.Ca)};Bda=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Ca;Ada(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.II=function(a){return $CLJS.F.g(a,$CLJS.Hd)?$CLJS.Hd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.JI=function(a,b){if($CLJS.z(b)){var c=$CLJS.ue.h?$CLJS.ue.h(b):$CLJS.ue.call(null,b);Bda(c,$CLJS.II(a));return $CLJS.od($CLJS.z(c),$CLJS.pd(b))}return $CLJS.Jc};$CLJS.KI=new $CLJS.K(null,"second","second",-444702010);$CLJS.LI=new $CLJS.K(null,"second-of-minute","second-of-minute",222734326);
$CLJS.MI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.NI=new $CLJS.K(null,"millisecond","millisecond",-540123566);$CLJS.OI=new $CLJS.K(null,"year-of-era","year-of-era",682445876);Cda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
Dda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.PI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.QI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);
Eda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Fda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);Gda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);
Hda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.RI=new $CLJS.K("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.SI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Ida=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var TI=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.yi,$CLJS.xj,$CLJS.wj,$CLJS.ii,$CLJS.bk,$CLJS.si,$CLJS.ci,$CLJS.OI],null),UI=$CLJS.Yg(TI),Jda,Kda,Lda;$CLJS.Y(Gda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid date extraction unit"],null)],null),UI));var VI=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.pv,$CLJS.fj,$CLJS.oj,$CLJS.hi,$CLJS.ci],null),WI=$CLJS.Yg(VI);
$CLJS.Y(Fda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid date truncation unit"],null)],null),WI));$CLJS.XI=$CLJS.Wf.j($CLJS.tf,$CLJS.Wk.o(),$CLJS.Ye.g(VI,TI));Jda=$CLJS.Yg($CLJS.XI);$CLJS.Y($CLJS.QI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid date bucketing unit"],null)],null),Jda));var YI=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LI,$CLJS.$i,$CLJS.oi],null),ZI=$CLJS.Yg(YI);
$CLJS.Y(Eda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid time extraction unit"],null)],null),ZI));var $I=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.NI,$CLJS.KI,$CLJS.nv,$CLJS.ov],null),aJ=$CLJS.Yg($I);$CLJS.Y(Ida,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid time truncation unit"],null)],null),aJ));$CLJS.bJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Wk.o(),$CLJS.Ye.g($I,YI));Kda=$CLJS.Yg($CLJS.bJ);
$CLJS.Y(Dda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid time bucketing unit"],null)],null),Kda));$CLJS.cJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Wk.o(),$CLJS.Ye.l($I,VI,$CLJS.G([YI,TI])));Lda=$CLJS.Yg($CLJS.cJ);$CLJS.Y($CLJS.MI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid datetime bucketing unit"],null)],null),$CLJS.cJ));var Mda=$CLJS.$d.g(Lda,$CLJS.Wh);
$CLJS.Y($CLJS.VF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid temporal bucketing unit"],null)],null),Mda));var Nda=$CLJS.qv.g(WI,aJ);$CLJS.Y($CLJS.PI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid datetime truncation unit"],null)],null),Nda));$CLJS.dJ=$CLJS.qv.g(UI,ZI);$CLJS.Y(Cda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid datetime extraction unit"],null)],null),$CLJS.dJ));
var eJ=$CLJS.$d.g(WI,$CLJS.ci);$CLJS.Y($CLJS.SI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid date interval unit"],null)],null),eJ));$CLJS.Y($CLJS.TF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid time interval unit"],null)],null),aJ));var Oda=$CLJS.qv.g(eJ,aJ);$CLJS.Y($CLJS.UF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.vu,"Valid datetime interval unit"],null)],null),Oda));
$CLJS.Y(Hda,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.RI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,$CLJS.VF],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.xt],null)],null));