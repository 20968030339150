var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var Tla,Ula,q3,r3,Vla,s3,t3;
$CLJS.m3=function(a){return $CLJS.n($CLJS.U0.g?$CLJS.U0.g($CLJS.N0,a):$CLJS.U0.call(null,$CLJS.N0,a))?$CLJS.Z2:$CLJS.n($CLJS.U0.g?$CLJS.U0.g($CLJS.J0,a):$CLJS.U0.call(null,$CLJS.J0,a))?$CLJS.Z2:$CLJS.n($CLJS.U0.g?$CLJS.U0.g($CLJS.G0,a):$CLJS.U0.call(null,$CLJS.G0,a))?$CLJS.Dla:$CLJS.n($CLJS.U0.g?$CLJS.U0.g($CLJS.Q0,a):$CLJS.U0.call(null,$CLJS.Q0,a))?$CLJS.Ela:$CLJS.n($CLJS.U0.g?$CLJS.U0.g($CLJS.P0,a):$CLJS.U0.call(null,$CLJS.P0,a))?$CLJS.Fla:$CLJS.n($CLJS.U0.g?$CLJS.U0.g($CLJS.F0,a):$CLJS.U0.call(null,
$CLJS.F0,a))?$CLJS.Gla:$CLJS.n($CLJS.U0.g?$CLJS.U0.g($CLJS.K0,a):$CLJS.U0.call(null,$CLJS.K0,a))?$CLJS.Jla:$CLJS.n($CLJS.U0.g?$CLJS.U0.g($CLJS.C0,a):$CLJS.U0.call(null,$CLJS.C0,a))?$CLJS.Hla:$CLJS.n($CLJS.U0.g?$CLJS.U0.g($CLJS.E0,a):$CLJS.U0.call(null,$CLJS.E0,a))?$CLJS.Ila:$CLJS.Kla};$CLJS.n3=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.K?a.T:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.o3=function(a,b){a=$CLJS.n3(a);b=$CLJS.n(b)?b:$CLJS.pv;return 0===a?$CLJS.F.g(b,$CLJS.pv)?$CLJS.zG("Today"):$CLJS.XF("This {0}",$CLJS.G([$CLJS.h3.h(b)])):$CLJS.F.g(a,1)?$CLJS.F.g(b,$CLJS.pv)?$CLJS.zG("Tomorrow"):$CLJS.XF("Next {0}",$CLJS.G([$CLJS.h3.h(b)])):$CLJS.F.g(a,-1)?$CLJS.F.g(b,$CLJS.pv)?$CLJS.zG("Yesterday"):$CLJS.XF("Previous {0}",$CLJS.G([$CLJS.h3.h(b)])):0>a?$CLJS.XF("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.h3.g(Math.abs(a),b)])):0<a?$CLJS.XF("Next {0} {1}",$CLJS.G([a,$CLJS.h3.g(a,
b)])):null};$CLJS.p3=function(a){var b=$CLJS.m3(a);b=$CLJS.De(b);return null==b?a:$CLJS.S.j(a,$CLJS.$M,b)};Tla=new $CLJS.K(null,"legacy-filter","legacy-filter",-948552546);Ula=new $CLJS.K(null,"query-filters","query-filters",409521440);q3=new $CLJS.K("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);r3=new $CLJS.K("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);Vla=new $CLJS.K(null,"matching-filters","matching-filters",-326129659);
s3=new $CLJS.K("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);t3=new $CLJS.K("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var u3=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nt,$CLJS.gt],null)),v3=null,w3=0,x3=0;;)if(x3<w3){var Wla=v3.X(null,x3);$CLJS.gH(Wla,s3);x3+=1}else{var y3=$CLJS.z(u3);if(y3){var z3=y3;if($CLJS.zd(z3)){var A3=$CLJS.kc(z3),Xla=$CLJS.lc(z3),Yla=A3,Zla=$CLJS.E(A3);u3=Xla;v3=Yla;w3=Zla}else{var $la=$CLJS.B(z3);$CLJS.gH($la,s3);u3=$CLJS.C(z3);v3=null;w3=0}x3=0}else break}
for(var B3=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.pI],null)),C3=null,D3=0,E3=0;;)if(E3<D3){var ama=C3.X(null,E3);$CLJS.gH(ama,r3);E3+=1}else{var F3=$CLJS.z(B3);if(F3){var G3=F3;if($CLJS.zd(G3)){var H3=$CLJS.kc(G3),bma=$CLJS.lc(G3),cma=H3,dma=$CLJS.E(H3);B3=bma;C3=cma;D3=dma}else{var ema=$CLJS.B(G3);$CLJS.gH(ema,r3);B3=$CLJS.C(G3);C3=null;D3=0}E3=0}else break}
for(var I3=$CLJS.z(new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Ds,$CLJS.Fs,$CLJS.zs,$CLJS.Bs,$CLJS.PH,$CLJS.iI,$CLJS.XH,$CLJS.QH],null)),J3=null,K3=0,L3=0;;)if(L3<K3){var fma=J3.X(null,L3);$CLJS.gH(fma,t3);L3+=1}else{var M3=$CLJS.z(I3);if(M3){var N3=M3;if($CLJS.zd(N3)){var O3=$CLJS.kc(N3),gma=$CLJS.lc(N3),hma=O3,ima=$CLJS.E(O3);I3=gma;J3=hma;K3=ima}else{var jma=$CLJS.B(N3);$CLJS.gH(jma,t3);I3=$CLJS.C(N3);J3=null;K3=0}L3=0}else break}
for(var P3=$CLJS.z(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.yH,$CLJS.KH,$CLJS.AH,$CLJS.HH,$CLJS.it],null)),Q3=null,R3=0,S3=0;;)if(S3<R3){var kma=Q3.X(null,S3);$CLJS.gH(kma,q3);S3+=1}else{var T3=$CLJS.z(P3);if(T3){var U3=T3;if($CLJS.zd(U3)){var V3=$CLJS.kc(U3),lma=$CLJS.lc(U3),mma=V3,nma=$CLJS.E(V3);P3=lma;Q3=mma;R3=nma}else{var oma=$CLJS.B(U3);$CLJS.gH(oma,q3);P3=$CLJS.C(U3);Q3=null;R3=0}S3=0}else break}
$CLJS.S1.m(null,$CLJS.KX,function(a,b){var c=$CLJS.De($CLJS.KX.h($CLJS.gY(a,b)));return $CLJS.n(c)?$CLJS.XF("Filtered by {0}",$CLJS.G([$CLJS.e3($CLJS.zG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.r1.v(a,b,v,$CLJS.s1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);
return $CLJS.ce($CLJS.r1.v(a,b,t,$CLJS.s1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}())])):null});
$CLJS.P1.m(null,s3,function(a,b,c,d){c=$CLJS.z(c);var e=$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var f=$CLJS.C(c);return $CLJS.e3(function(){var k=e instanceof $CLJS.K?e.T:null;switch(k){case "and":return $CLJS.zG("and");case "or":return $CLJS.zG("or");default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}(),function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<
v){var D=$CLJS.kd(u,A);D=$CLJS.r1.v(a,b,D,d);x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.r1.v(a,b,x,d),m($CLJS.Kc(t)))}return null}},null,null)}(f)}())});
$CLJS.P1.m(null,r3,function(a,b,c,d){var e=$CLJS.z(c);c=$CLJS.B(e);e=$CLJS.C(e);$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.Ze.g(function(k){return $CLJS.r1.v(a,b,k,d)},f);if($CLJS.F.g($CLJS.E(f),2))switch(f=$CLJS.H(e,0,null),e=$CLJS.H(e,1,null),c=c instanceof $CLJS.K?c.T:null,c){case "\x3d":return $CLJS.XF("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.XF("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}else switch(f=$CLJS.B(e),e=$CLJS.e3($CLJS.zG("or"),
$CLJS.Kc(e)),c=c instanceof $CLJS.K?c.T:null,c){case "\x3d":return $CLJS.XF("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.XF("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.P1.m(null,t3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);var f=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);f=$CLJS.r1.v(a,b,f,d);a=$CLJS.r1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "\x3c":return $CLJS.XF("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.XF("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.XF("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.XF("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.XF("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.XF("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.XF("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.XF("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.P1.m(null,$CLJS.zH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.XF("{0} is between {1} and {2}",$CLJS.G([$CLJS.r1.v(a,b,e,d),$CLJS.r1.v(a,b,f,d),$CLJS.r1.v(a,b,c,d)]))});
$CLJS.P1.m(null,$CLJS.FH,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.H(c,2,null),k=$CLJS.H(c,3,null),l=$CLJS.H(c,4,null),m=$CLJS.H(c,5,null),t=$CLJS.H(c,6,null);c=$CLJS.H(c,7,null);return $CLJS.r1.v(a,b,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.nt,e,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.zH,e,f,t,l],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.zH,e,k,m,c],null)],null),d)});
$CLJS.P1.m(null,q3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.r1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "is-null":return $CLJS.XF("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.XF("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.XF("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.XF("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.XF("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.h(e)].join(""));}});$CLJS.P1.m(null,$CLJS.jI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.XF("{0} is within {1}",$CLJS.G([$CLJS.r1.v(a,b,e,d),$CLJS.o3(f,c).toLowerCase()]))});
$CLJS.W3=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){return $CLJS.d3($CLJS.Gs,$CLJS.ce(c,$CLJS.ce(d,e)))}a.A=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.X3=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.SD(f),$CLJS.vM))f=$CLJS.SX(f);else return e=$CLJS.n(e)?e:-1,f=$CLJS.TX.h(f),$CLJS.pY.l(d,e,$CLJS.Fk,$CLJS.G([$CLJS.KX,$CLJS.GF($CLJS.$d,$CLJS.tf),f]))}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Y3=function(){function a(d,e){return $CLJS.De($CLJS.KX.h($CLJS.gY(d,$CLJS.n(e)?e:-1)))}function b(d){return c.g?c.g(d,null):c.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Z3=function(){function a(d,e){var f=$CLJS.gY(d,e);return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Ck.g($CLJS.Ze.h($CLJS.p3),$CLJS.af($CLJS.$M)),$CLJS.$1.j(d,e,f)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.$3=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){c=$CLJS.wd(c)?$CLJS.RH.h(c):$CLJS.rh.h(c);return $CLJS.RX($CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.N,$CLJS.TX.h(d)],null),$CLJS.Ze.g($CLJS.TX,e)))}a.A=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.pma=function(){function a(d,e,f){var k=$CLJS.H(f,0,null);$CLJS.H(f,1,null);f=$CLJS.H(f,2,null);var l=$CLJS.gY(d,e);l=$CLJS.$1.j(d,e,l);d=$CLJS.V2.v(d,e,f,l);d=$CLJS.gJ(function(m){return $CLJS.F.g($CLJS.RH.h(m),k)},$CLJS.m3(d));return $CLJS.n(d)?d:$CLJS.Y2.h(k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;
c.j=a;return c}();
$CLJS.qma=function(){function a(d,e,f){var k=$CLJS.d_(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iO,$CLJS.BH],null),f);d=$CLJS.uf($CLJS.Y3.g(d,e));e=$CLJS.bf(function(l){return $CLJS.F.g($CLJS.d_(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iO,$CLJS.BH],null),$CLJS.n_.h(l)),k)},d);if($CLJS.z(e)){if($CLJS.C(e))throw $CLJS.ai("Multiple matching filters found",new $CLJS.h(null,3,[Tla,k,Ula,d,Vla,e],null));return $CLJS.B(e)}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,
e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.rma=function(){function a(d,e,f){f=$CLJS.T_.j(d,e,f);d=$CLJS.Z3.g(d,e);return $CLJS.V2.g(f,d)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();