var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var kda,lda,mda,nda,oda,pda,qda,DI,rda,sda,tda,uda,vda,wda,xda,yda,FI,zda;$CLJS.zI=new $CLJS.K(null,"supported-field","supported-field",-2061545519);kda=new $CLJS.K("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.AI=new $CLJS.K("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);lda=new $CLJS.K("mbql.clause","count-where","mbql.clause/count-where",2010666417);
mda=new $CLJS.K("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.BI=new $CLJS.K(null,"requires-column?","requires-column?",1623131448);nda=new $CLJS.K("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.CI=new $CLJS.K(null,"display-info","display-info",-816930907);oda=new $CLJS.K("mbql.clause","max","mbql.clause/max",1615385533);pda=new $CLJS.K("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
qda=new $CLJS.K("mbql.clause","median","mbql.clause/median",-1026386338);DI=new $CLJS.K("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);rda=new $CLJS.K("mbql.clause","min","mbql.clause/min",2074448531);sda=new $CLJS.K("mbql.clause","percentile","mbql.clause/percentile",2064253651);tda=new $CLJS.K("mbql.clause","stddev","mbql.clause/stddev",659381076);uda=new $CLJS.K("mbql.clause","var","mbql.clause/var",809434920);
vda=new $CLJS.K("mbql.clause","share","mbql.clause/share",-37921592);wda=new $CLJS.K("mbql.clause","count","mbql.clause/count",-454225910);xda=new $CLJS.K("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.EI=new $CLJS.K(null,"driver-feature","driver-feature",1905324248);yda=new $CLJS.K("mbql.clause","avg","mbql.clause/avg",1826863173);FI=new $CLJS.K("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.GI=new $CLJS.K("operator","aggregation","operator/aggregation",-1961510924);zda=new $CLJS.K("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.pH($CLJS.Iz,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null)],null)],null)],null)]));$CLJS.pH($CLJS.ZH,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null)],null)],null)],null)]));
$CLJS.nH($CLJS.SH,$CLJS.G([$CLJS.lu,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.nH($CLJS.CH,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null)],null)]));$CLJS.nH($CLJS.gI,$CLJS.G([$CLJS.lu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)]));
$CLJS.nH($CLJS.Zj,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)],null)]));$CLJS.gH($CLJS.Zj,$CLJS.TG);$CLJS.nH($CLJS.lI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.gH($CLJS.lI,$CLJS.TG);$CLJS.nH($CLJS.Ln,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)],null)]));$CLJS.gH($CLJS.Ln,$CLJS.TG);
$CLJS.Y(DI,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.nt,new $CLJS.h(null,1,[$CLJS.vu,"valid percentile"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.nH($CLJS.JH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DI],null)]));$CLJS.gH($CLJS.JH,$CLJS.TG);
$CLJS.nH($CLJS.eI,$CLJS.G([$CLJS.lu,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)]));$CLJS.nH($CLJS.nI,$CLJS.G([$CLJS.lu,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.nH($CLJS.kI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));
$CLJS.nH($CLJS.uI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.gH($CLJS.kI,$CLJS.TG);$CLJS.gH($CLJS.uI,$CLJS.TG);$CLJS.nH($CLJS.sI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)]));$CLJS.gH($CLJS.sI,$CLJS.TG);
$CLJS.nH($CLJS.DH,$CLJS.G([$CLJS.lu,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.Y(FI,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.gt,yda,wda,pda,lda,kda,oda,qda,rda,sda,vda,tda,zda,xda,mda,uda,$CLJS.pl],null));$CLJS.Y($CLJS.AI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qj,new $CLJS.h(null,1,[$CLJS.Ln,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FI],null)],null));
$CLJS.HI=new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.h(null,4,[$CLJS.RH,$CLJS.Iz,$CLJS.BI,!1,$CLJS.EI,$CLJS.sH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.bG,$CLJS.zG("Count of rows"),$CLJS.IH,$CLJS.zG("Count"),$CLJS.Su,$CLJS.zG("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.kI,$CLJS.zI,$CLJS.Wj,$CLJS.BI,!0,$CLJS.EI,$CLJS.sH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.bG,$CLJS.zG("Sum of ..."),$CLJS.IH,$CLJS.zG("Sum"),$CLJS.Su,$CLJS.zG("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.SH,$CLJS.zI,$CLJS.Wj,$CLJS.BI,!0,$CLJS.EI,$CLJS.sH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.bG,$CLJS.zG("Average of ..."),$CLJS.IH,$CLJS.zG("Average"),$CLJS.Su,$CLJS.zG("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.lI,$CLJS.zI,$CLJS.Wj,$CLJS.BI,!0,$CLJS.EI,$CLJS.UH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.bG,$CLJS.zG("Median of ..."),$CLJS.IH,$CLJS.zG("Median"),$CLJS.Su,$CLJS.zG("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.CH,$CLJS.zI,$CLJS.yt,$CLJS.BI,!0,$CLJS.EI,$CLJS.sH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.bG,$CLJS.zG("Number of distinct values of ..."),$CLJS.IH,$CLJS.zG("Distinct values"),$CLJS.Su,$CLJS.zG("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.uI,$CLJS.zI,$CLJS.Wj,$CLJS.BI,!0,$CLJS.EI,$CLJS.sH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.bG,$CLJS.zG("Cumulative sum of ..."),
$CLJS.IH,$CLJS.zG("Sum"),$CLJS.Su,$CLJS.zG("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.RH,$CLJS.ZH,$CLJS.BI,!1,$CLJS.EI,$CLJS.sH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.bG,$CLJS.zG("Cumulative count of rows"),$CLJS.IH,$CLJS.zG("Count"),$CLJS.Su,$CLJS.zG("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.nI,$CLJS.zI,$CLJS.Wj,$CLJS.BI,
!0,$CLJS.EI,$CLJS.hI,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.bG,$CLJS.zG("Standard deviation of ..."),$CLJS.IH,$CLJS.zG("SD"),$CLJS.Su,$CLJS.zG("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.Ln,$CLJS.zI,$CLJS.Oi,$CLJS.BI,!0,$CLJS.EI,$CLJS.sH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.bG,$CLJS.zG("Minimum of ..."),$CLJS.IH,$CLJS.zG("Min"),$CLJS.Su,$CLJS.zG("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.Zj,$CLJS.zI,$CLJS.Oi,$CLJS.BI,!0,$CLJS.EI,$CLJS.sH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.bG,$CLJS.zG("Maximum of ..."),$CLJS.IH,$CLJS.zG("Max"),$CLJS.Su,$CLJS.zG("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(nda,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gs,$CLJS.GI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RH,$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ct],null),$CLJS.Ze.h($CLJS.RH),$CLJS.HI)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zI,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.Ni],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.BI,$CLJS.xt],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.EI,$CLJS.Ni],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CI,$CLJS.md],null)],null));