var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var Cna=function(a,b){return new $CLJS.le(null,function(){a:for(var c=a,d=b;;){d=$CLJS.z(d);var e;if(e=d)e=$CLJS.B(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Kc(d);else break a}return d},null,null)},Dna=function(a,b){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ov(a,b),Cna(a,b)],null)},z5=function(a){var b=$CLJS.Uk.l;var c=$CLJS.O(a);var d=$CLJS.I.g(c,$CLJS.s5);c=$CLJS.I.g(c,$CLJS.t5);if($CLJS.n($CLJS.n(d)?$CLJS.F.g(c,"currency"):d)){c=$CLJS.n5.h;d=$CLJS.rh.h(d);var e=$CLJS.q($CLJS.k5);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.n5,d);c=new $CLJS.h(null,2,[$CLJS.r5,c,$CLJS.u5,c],null)}else c=new $CLJS.h(null,1,[$CLJS.u5,2],null);b=b.call($CLJS.Uk,$CLJS.G([c,a]));return $CLJS.n($CLJS.v5.h(a))?$CLJS.S.l($CLJS.uk.g(b,$CLJS.v5),$CLJS.u5,$CLJS.v5.h(a),$CLJS.G([$CLJS.r5,$CLJS.v5.h(a)])):b},A5=function(a,b){if(null!=a&&null!=a.Ne)a=a.Ne(a,b);else{var c=A5[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=A5._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("NumberFormatter.format-number-basic",
a);}return a},Ena=function(a,b){var c=$CLJS.DB(a.formatToParts(b),$CLJS.G([new $CLJS.h(null,1,[$CLJS.ri,!0],null)]));a=$CLJS.n($CLJS.Je(function(d){return $CLJS.F.g($CLJS.dj.h(d),"exponentMinusSign")},c))?c:function(){var d=Dna(function(f){return $CLJS.Ak.g($CLJS.dj.h(f),"exponentInteger")},c),e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.Ye.l(e,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.dj,"exponentPlusSign",$CLJS.uj,"+"],null)],null),$CLJS.G([d]))}();return $CLJS.P.g($CLJS.p,
$CLJS.Ze.g(function(d){var e=$CLJS.O(d);d=$CLJS.I.g(e,$CLJS.dj);e=$CLJS.I.g(e,$CLJS.uj);switch(d){case "exponentSeparator":return"e";default:return e}},a))},Fna=function(a,b){switch($CLJS.t5.h(b)){case "scientific":return function(c){return Ena(a,c)};default:return function(c){return a.format(c)}}},B5=function(a,b,c,d,e){this.options=a;this.vg=b;this.yg=c;this.de=d;this.di=e;this.C=393216;this.I=0},Gna=function(a,b){return $CLJS.P.g($CLJS.p,function(){return function e(d){return new $CLJS.le(null,
function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.kd(k,v),A=$CLJS.O(x);x=$CLJS.I.g(A,$CLJS.dj);A=$CLJS.I.g(A,$CLJS.uj);var D=new $CLJS.Tg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);D=D.h?D.h(x):D.call(null,x);$CLJS.n(D)&&(x=$CLJS.F.g(x,"integer")?b:A,m.add(x));v+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}var t=$CLJS.B(f);t=$CLJS.O(t);
var u=$CLJS.I.g(t,$CLJS.dj);t=$CLJS.I.g(t,$CLJS.uj);if($CLJS.n(function(){var v=new $CLJS.Tg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.ce($CLJS.F.g(u,"integer")?b:t,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}($CLJS.DB(a.vg.formatToParts(1),$CLJS.G([$CLJS.ri,!0])))}())},C5=function(a){var b=$CLJS.F.g($CLJS.t5.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.Xk($CLJS.wC($CLJS.Ta,$CLJS.Ag([$CLJS.zna,
$CLJS.rna,$CLJS.una,$CLJS.wna,$CLJS.Bna,$CLJS.s5,$CLJS.vna,$CLJS.xna,$CLJS.sna,$CLJS.tna],[$CLJS.u5.g(a,b),$CLJS.x5.h(a),$CLJS.w5.h(a),$CLJS.yna.h(a),$CLJS.F.g($CLJS.t5.h(a),"scientific")?null:$CLJS.t5.g(a,"decimal"),$CLJS.s5.h(a),!0,$CLJS.r5.g(a,b),$CLJS.F.g($CLJS.t5.h(a),"scientific")?"scientific":null,$CLJS.y5.h(a)]))));var c=$CLJS.x5.h(a);var d=$CLJS.s5.h(a);c=$CLJS.n(d)?null==c||$CLJS.F.g(c,"symbol"):d;d=Fna(b,a);return new B5(a,b,c,d,$CLJS.N)},D5=function(a,b){return C5(z5(b)).Ne(null,a)},F5=
function(a,b){var c=$CLJS.uk.l(b,$CLJS.nD,$CLJS.G([$CLJS.t5])),d=Math.abs(a);if(0===a)return"0";if(1E3>d)return $CLJS.E5.g?$CLJS.E5.g(a,c):$CLJS.E5.call(null,a,c);b=$CLJS.B($CLJS.bf(function(f){return d>=$CLJS.B(f)},Hna));var e=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.Uk.l($CLJS.G([c,new $CLJS.h(null,2,[$CLJS.r5,1,$CLJS.u5,1],null)]));return $CLJS.E5.g?$CLJS.E5.g(f,k):$CLJS.E5.call(null,f,k)}()),$CLJS.p.h(b)].join("")},Ina=function(a,b){var c=z5(b);if($CLJS.n(G5.h(c)))b=
G5.h(c);else if($CLJS.Sa($CLJS.v5.h(c))&&$CLJS.Sa($CLJS.r5.h(c))&&$CLJS.Ak.g($CLJS.t5.h(c),"currency")&&Math.abs(a)<($CLJS.F.g($CLJS.t5.h(c),"percent")?.01:1)){b=$CLJS.S.j;var d=$CLJS.uk.g(c,$CLJS.u5);c=$CLJS.y5.g(c,0);b=C5(b.call($CLJS.S,d,$CLJS.w5,2>c?2:c))}else b=C5(c);return A5(b,a)};B5.prototype.P=function(a,b){return new B5(this.options,this.vg,this.yg,this.de,b)};B5.prototype.O=function(){return this.di};
B5.prototype.Ne=function(a,b){a=this.de.h?this.de.h(b):this.de.call(null,b);var c=$CLJS.Ana.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.Ak.g(c,".,"):c)&&(b=$CLJS.B(c),c=$CLJS.hd(c),a=$CLJS.tC(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.yg)&&(b=$CLJS.s5.h(this.options),c=$CLJS.l5(b),a=$CLJS.tC($CLJS.tC(a,[$CLJS.Zg(b)," "].join(""),c),$CLJS.Zg(b),c));return a};
var G5=new $CLJS.K(null,"number-formatter","number-formatter",929788393),H5=new $CLJS.K(null,"scale","scale",-230427353),I5=new $CLJS.K(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),Jna=new $CLJS.K(null,"scientific","scientific",316285837);var J5=new $CLJS.h(null,1,[$CLJS.x5,"symbol"],null),Kna=$CLJS.Xk(J5),Hna=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[1E12,"T"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E9,"B"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E6,"M"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E3,"k"],null)],null),K5=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.Zi,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.I.g(f,$CLJS.t5)},e,a,b,c,d)}();K5.m(null,$CLJS.Wh,function(a,b){return F5(a,b)});K5.m(null,"percent",function(a,b){return[$CLJS.p.h(F5(100*a,b)),"%"].join("")});K5.m(null,"currency",function(a,b){b=$CLJS.Uk.l($CLJS.G([b,J5]));var c=C5(b);return 1E3>Math.abs(a)?c.Ne(null,a):Gna(c,F5(a,b))});K5.m(null,"scientific",function(a,b){return D5(a,$CLJS.Uk.l($CLJS.G([b,new $CLJS.h(null,2,[$CLJS.u5,1,$CLJS.r5,1],null)])))});
$CLJS.E5=function E5(a,b){b=$CLJS.TC(b);var d=$CLJS.O(b);b=$CLJS.I.g(d,$CLJS.nD);var e=$CLJS.I.g(d,I5),f=$CLJS.I.g(d,$CLJS.t5),k=$CLJS.I.g(d,H5);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.uk.g(d,H5),E5.g?E5.g(b,f):E5.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.S.j(d,I5,!1);return E5.g?E5.g(l,m):E5.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?K5.g(a,z5($CLJS.uk.g(d,$CLJS.nD))):$CLJS.F.g($CLJS.rh.h(f),Jna)?D5(a,d):Ina(a,d)};
module.exports={compact_currency_options_js:Kna,format_number:$CLJS.E5};